export default [
  {
    path: '/',
    name: 'dashboard',
    component: () => import('@/views/Dashboard.vue'),
    meta: {
      pageTitle: 'dashboard',
      resource: 'account_overview',
      action: 'view',
    },
  },
  {
    path: '/account-management',
    name: 'account-management.account-overview',
    component: () => import('@/views/AccountManagement/Accounts.vue'),
    meta: {
      pageTitle: 'account_management',
      breadcrumb: [
        {
          text: 'account_overview',
          active: true,
        },
      ],
      resource: 'account_overview',
      action: 'view',
    },
  },
  {
    path: '/account-management/sub-account',
    name: 'account-management.sub-account-management',
    component: () => import('@/views/AccountManagement/SubAccounts.vue'),
    meta: {
      pageTitle: 'account_management',
      breadcrumb: [
        {
          text: 'sub_account_mgmt',
          active: true,
        },
      ],
      resource: 'sub_account',
      action: 'manage',
    },
  },
  {
    path: '/report/game-history',
    name: 'report.game-history',
    component: () => import('@/views/Report/GameHistory.vue'),
    meta: {
      pageTitle: 'report',
      breadcrumb: [
        {
          text: 'game_history',
          active: true,
        },
      ],
      resource: 'report',
      action: 'game_history',
    },
  },
  {
    path: '/report/game-transactions',
    name: 'report.game-transactions',
    component: () => import('@/views/Report/GameTransactions.vue'),
    meta: {
      pageTitle: 'report',
      breadcrumb: [
        {
          text: 'game_transactions',
          active: true,
        },
      ],
      resource: 'report',
      action: 'game_transactions',
    },
  },
  {
    path: '/report/win-lose',
    name: 'report.win-lose',
    component: () => import('@/views/Report/WinLose.vue'),
    meta: {
      pageTitle: 'report',
      breadcrumb: [
        {
          text: 'win_lose',
          active: true,
        },
      ],
      resource: 'report',
      action: 'win_lose',
    },
  },
  {
    path: '/report/transactions',
    name: 'report.transactions',
    component: () => import('@/views/Report/Transactions.vue'),
    meta: {
      pageTitle: 'report',
      breadcrumb: [
        {
          text: 'transactions',
          active: true,
        },
      ],
      resource: 'report',
      action: 'transactions',
    },
  },
  {
    path: '/report/balance',
    name: 'report.balance',
    component: () => import('@/views/Report/Balance.vue'),
    meta: {
      pageTitle: 'report',
      breadcrumb: [
        {
          text: 'balance',
          active: true,
        },
      ],
      resource: 'report',
      action: 'balance',
    },
  },
  {
    path: '/report/online-user',
    name: 'report.online-user',
    component: () => import('@/views/Report/OnlineUser.vue'),
    meta: {
      pageTitle: 'report',
      breadcrumb: [
        {
          text: 'online_user',
          active: true,
        },
      ],
      resource: 'report',
      action: 'online_user',
    },
  },
  {
    path: '/setting/log',
    name: 'setting.log',
    component: () => import('@/views/Setting/Log.vue'),
    meta: {
      pageTitle: 'setting',
      breadcrumb: [
        {
          text: 'log',
          active: true,
        },
      ],
      resource: 'setting',
      action: 'log',
    },
  },
  {
    path: '/setting/set-limit',
    name: 'setting.set_limit',
    component: () => import('@/views/Setting/SetLimit.vue'),
    meta: {
      pageTitle: 'setting',
      breadcrumb: [
        {
          text: 'set_limit',
          active: true,
        },
      ],
      resource: 'setting',
      action: 'log',
    },
  },
  {
    path: '/setting/change-password',
    name: 'setting.change-password',
    component: () => import('@/views/Setting/ChangePassword.vue'),
    meta: {
      pageTitle: 'setting',
      breadcrumb: [
        {
          text: 'change_password',
          active: true,
        },
      ],
      resource: 'setting',
      action: 'change_password',
    },
  },
  {
    path: '/audit',
    name: 'audit.audit',
    component: () => import('@/views/Audit/Audit.vue'),
    meta: {
      pageTitle: 'audit',
      breadcrumb: [
        {
          text: 'audit',
          active: true,
        },
      ],
      resource: 'audit',
      action: 'view',
    },
  },
  {
    path: '/audit/ban-list',
    name: 'audit.ban_list',
    component: () => import('@/views/Audit/BanList.vue'),
    meta: {
      pageTitle: 'audit',
      breadcrumb: [
        {
          text: 'ban_list',
          active: true,
        },
      ],
      resource: 'audit',
      action: 'view',
    },
  },
  {
    path: '/audit/unsettled',
    name: 'audit.unsettled',
    component: () => import('@/views/Audit/Unsettled.vue'),
    meta: {
      pageTitle: 'audit',
      breadcrumb: [
        {
          text: 'unsettled',
          active: true,
        },
      ],
      resource: 'audit',
      action: 'view',
    },
  },
]
